export default [{
  path: '/citihub',
  label: 'CitiHub',
  icon: 'ti ti-globe',
  permissions: ['citihub.registers.*', 'citihub.tickets.*', 'citihub.services.*'],
  order: 200,
  component: () => import('@/Modules/CitiHub/CitiHubView.vue'),
  children: [
    {
      path: 'tickets',
      name: 'citihub-tickets',
      label: 'Atividades',
      permissions: ['citihub.tickets.*'],
      icon: 'ti ti-exchange',
      component: () => import('@/Modules/CitiHub/Tickets/TicketView.vue'),
      children: [
        {
          path: 'occurrences',
          redirect: 'citihub-tickets-tickets-list',
          label: 'Ocorrências',
          icon: 'ti ti-eye',
          permissions: ['citihub.tickets.tickets'],
          component: () => import('@/Modules/CitiHub/Tickets/Tickets/TicketView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'citihub-tickets-tickets-list',
              component: () => import('@/Modules/CitiHub/Tickets/Tickets/Sections/ListSection.vue'),
            },
            {
              path: 'create',
              name: 'citihub-tickets-tickets-create',
              component: () => import('@/Modules/CitiHub/Tickets/Tickets/Sections/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'citihub-tickets-tickets-update',
              component: () => import('@/Modules/CitiHub/Tickets/Tickets/Sections/FormView.vue'),
            }
          ]
        },
      ]
    },
    {
      path: 'services',
      name: 'citihub-services',
      label: 'Ordens de Serviços',
      permissions: ['citihub.services.*'],
      icon: 'ti ti-printer',
      component: () => import('@/Modules/CitiHub/Services/ServiceView.vue'),
      children: [
        {
          path: 'orders',
          redirect: 'citihub-services-orders-list',
          label: 'Ordem de Serviço',
          icon: 'ti ti-eye',
          permissions: ['citihub.services.orders'],
          component: () => import('@/Modules/CitiHub/Services/Orders/OrderView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'citihub-services-orders-list',
              component: () => import('@/Modules/CitiHub/Services/Orders/Sections/ListSection.vue'),
            },
            {
              path: 'create',
              name: 'citihub-services-orders-create',
              component: () => import('@/Modules/CitiHub/Services/Orders/Sections/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'citihub-services-orders-update',
              component: () => import('@/Modules/CitiHub/Services/Orders/Sections/FormView.vue'),
            }
          ]
        },
      ]
    }
  ]
}];