export default [{
  path: '/contracts',
  label: 'Contratos',
  icon: 'ti ti-file',
  permissions: ['contracts.registers.*'],
  order: 200,
  component: () => import('@/Modules/Contracts/ContractView.vue'),
  children: [
    {
      path: 'contracts',
      name: 'contract-contracts',
      label: 'Contratos',
      permissions: ['citihub.registers.*'],
      icon: 'ti ti-exchange',
      component: () => import('@/Modules/Contracts/Registers/ContractRegisterView.vue'),
      children: [
        {
          path: 'contracts',
          redirect: 'contracts-contracts-contracts-list',
          label: 'Contratos',
          icon: 'ti ti-file',
          permissions: ['contracts.registers.contracts'],
          component: () => import('@/Modules/Contracts/Registers/Contracts/ContractView.vue'),
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'contracts-contracts-contracts-list',
              component: () => import('@/Modules/Contracts/Registers/Contracts/Sections/ListSection.vue'),
            },
            {
              path: 'create',
              name: 'contracts-contracts-contracts-create',
              component: () => import('@/Modules/Contracts/Registers/Contracts/Sections/FormView.vue'),
            },
            {
              path: 'update/:id',
              name: 'contracts-contracts-contracts-update',
              component: () => import('@/Modules/Contracts/Registers/Contracts/Sections/FormView.vue'),
            }
          ]
        },
      ]
    }
  ]
}];