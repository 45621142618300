export default [{
  path: '/ai',
  label: 'Assistente IA',
  icon: 'ti ti-robot',
  permissions: ['botai.registers.*', 'botai.messages.*'],
  order: 200,
  component: () => import('@/Modules/Botai/BotAIView.vue'),
  children: [
    {
      path: 'chat',
      name: 'botai-messages',
      label: 'Conversas',
      permissions: ['botai.messages.*'],
      icon: 'ti ti-comment',
      component: () => import('@/Modules/Botai/Messages/MessageView.vue'),
      children: [
        {
          path: 'messages',
          label: 'Minhas Conversas',
          icon: 'ti ti-comment',
          permissions: ['botai.messages.messages'],
          redirect: 'botai-messages-list',
          ignore_children: true,
          children: [
            {
              path: '',
              name: 'botai-messages-list',
              component: () => import('@/Modules/Botai/Messages/Views/ListAssistantSection.vue'),
            },
            {
              path: 'v/:assistant_slug',
              name: 'botai-messages-messages',
              component: () => import('@/Modules/Botai/Messages/Views/ListMessageSection.vue'),
            },
          ]
        },
      ]
    }
  ]
}];